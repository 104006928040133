import { BoxProps, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import {
    CardElement,
    PaymentRequestButtonElement,
    useElements,
    useStripe
} from "@stripe/react-stripe-js";
import { CanMakePaymentResult, Stripe, StripeCardElement, StripePaymentRequestButtonElementOptions } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import { InfoAlert } from "../../../../../common/components/Alert/Alert";
import { calculateDeposit, rem } from "../../../../../common/utils/formats";
import { PackageNameDto } from "../../../../../server/src/dto/packageName.dto";
import {
    GiftCardBalance,
    GiftCardBalanceRequest,
    GiftCardPaymentRequest,
    PMethod,
    Reservation,
    Venue,
} from "../../../store/types";
import CouponCodes from "../../OrderSummary/CouponCodes";
import NoAvailiableGuests from "../../PackageCard/NoAvailiableGuests";
import ExpressCheckout from "../../ReservationInfo/Payment/ExpressCheckout";
import PaymentContainer from "../../ReservationInfo/Payment/PaymentContainer";
import PaymentDeilimiter from "../../ReservationInfo/Payment/PaymentDeilimiter";
import VenuePolicies from "../../ReservationInfo/Payment/VenuePolicies";
import ButtonMain from "../ButtonMain/ButtonMain";
import MobileAttachedButton from "../MobileAttachedButton/MobileAttachedButton";
import StripeCustomButton from "./StripeCustomButton";
import './stripeForm.scss';
import StripeManageCards from "./StripeManageCards";
import { convertToStripeMethod, getEnabledPaymentMethods, StripeSavedCard } from "./utils";
interface Props {
    venue: Venue | undefined;
    reservation: Reservation;
    selected: boolean;
    isMobile: boolean;
    showGiftCard: boolean;
    orderSummary?: ((error?: string, block?: boolean) => JSX.Element) | null;
    uiConfig: any;
    requestError?: string;
    makeStripeReservation: (stripe: Stripe, elements?: StripeCardElement, otherMethods?: boolean, paymentMethod?: string, isSaveCard?: boolean) => void;
    paymentMethodStripe: (stripe: Stripe, ev: any) => void;
    giftBalance?: GiftCardBalance;
    getGiftCardBalance: (cardParams: GiftCardBalanceRequest) => void;
    giftBalanceError?: string;
    addGiftCard: (cardParams: GiftCardPaymentRequest) => void;
    giftCardAmount: number;
    showDiscounts?: boolean;
    applyCouponCode?: (couponCodes: string[]) => void;
    createEmptyPayment: (paymentType: PMethod) => void;
    isUpdateReservation: boolean;
    isUpdateWithVenueChange: boolean;
    oldReservation?: Reservation;
    isGuest: boolean;
    listOfStripeSavedCards: StripeSavedCard[];
    customStripeHandler?: (stripe: Stripe, elements?: StripeCardElement, otherMethods?: boolean, paymentMethod?: string) => void;
    currentPackage?: PackageNameDto;
    setSelected: (selected: boolean) => void;
}


const StripeForm = ({
    venue,
    reservation,
    selected,
    isMobile,
    orderSummary,
    uiConfig,
    requestError,
    showGiftCard,
    makeStripeReservation,
    paymentMethodStripe,
    customStripeHandler,
    giftBalance,
    getGiftCardBalance,
    giftBalanceError,
    addGiftCard,
    giftCardAmount,
    showDiscounts,
    applyCouponCode,
    createEmptyPayment,
    isUpdateReservation,
    isUpdateWithVenueChange,
    oldReservation,
    isGuest,
    listOfStripeSavedCards,
    currentPackage,
    setSelected
}: Props) => {
    const [succeeded, setSucceeded] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [processing, setProcessing] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [isSaveCard, setIsSaveCard] = useState<boolean>(false);
    const [paymentRequest, setPaymentRequest] = useState<any>(null);
    const [paymentResult, setPaymentResult] = useState<CanMakePaymentResult | null>(null);
    const [selectedSavedCard, setSelectedSavedCard] = useState<string | null>(null);
    const enabledPaymentMethods = getEnabledPaymentMethods(venue?.stripeAdditionalMethods || '', reservation.currency);
    const stripe = useStripe();
    const elements = useElements();
    const isSaveCardDisplaying = !isGuest && venue?.paymentType === PMethod.stripeV2


    const checkboxStyle: React.CSSProperties = {
        display: "flex",
        marginBottom: !venue?.showPolicy ? rem(24) : 0,
    };

    useEffect(() => {
        if (stripe) {
            const pr = stripe.paymentRequest({
                country: 'US',
                currency: 'usd',
                total: {
                    label: 'reservation',
                    amount: Math.round(deposit * 100),
                },
                requestPayerEmail: true,
            });
            pr.on('paymentmethod', (ev) => paymentMethodStripe(stripe, ev));

            // Check the availability of the Payment Request API.
            pr.canMakePayment().then(result => {
                if (result) {
                    setPaymentRequest(pr);
                    setPaymentResult(result);
                }
            });
        }
    }, [stripe]);


    const confirmButtonText = isUpdateReservation ? "MODIFY RESERVATION" : "COMPLETE YOUR RESERVATION";

    const paymentButtonOptions: StripePaymentRequestButtonElementOptions = {
        paymentRequest,
        style: {
            paymentRequestButton: {
                type: 'default',
                theme: 'dark',
                height: '48px',
            }
        }
    }

    if (!stripe || !elements) {
        return <></>;
    }

    const cardStyle = {
        style: {
            base: {
                color: "#32325d",
                fontFamily: 'Arial, sans-serif',
                fontSmoothing: "antialiased",
                fontSize: "16px",
                "::placeholder": {
                    color: "#32325d"
                }
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a"
            }
        }
    };

    const handleChange = async (event: any) => {
        setDisabled(event.empty);
        setSucceeded(event.empty);
        setError("");
    };
    const handleSubmit = async (ev: any) => {
        ev.preventDefault();
        setProcessing(true);

        try {
            const cardMethod = elements.getElement(CardElement);
            if (cardMethod !== null) {
                if (customStripeHandler) {
                    customStripeHandler(stripe, cardMethod)
                } else {
                    makeStripeReservation(stripe, cardMethod, false, undefined, isSaveCard);
                }
                setSucceeded(true);
                setProcessing(false);
            } else if (selectedSavedCard) {
                makeStripeReservation(stripe, undefined, false, selectedSavedCard, isSaveCard);
                setSucceeded(true);
                setProcessing(false);
            }
        } catch (e) {
            console.log(e)
            setError(`Sorry, we cannot complete payment. Please try again later.`);
            setDisabled(true);
            setProcessing(false);
        }
    };


    const handleOtherMethodsSubmit = (method: string) => {
        console.log('method', method)
        const isValidationFailed = handleAlternativeMethodsValidation(method);
        if (isValidationFailed) {
            return
        }
        const stripeMethod = convertToStripeMethod(method);
        setProcessing(true);
        try {
            if (customStripeHandler) {
                customStripeHandler(stripe, undefined, true, stripeMethod || '');
            } else {
                makeStripeReservation(stripe, undefined, true, stripeMethod || '');
            }
            setSucceeded(true);
            setProcessing(false);

        } catch (e) {
            console.log(e)
            setError(`Sorry, we cannot complete payment. Please try again later.`);
            setDisabled(true);
            setProcessing(false);
        }
    }

    const handleAlternativeMethodsValidation = (paymentMethod: string) => {
        if (paymentMethod === 'Affirm') {
            const amount = Math.round(deposit * 100)
            if (amount < 50) {
                setError(`Affirm is not available for this amount. Please try another payment method.`);
                return true
            }
        }
        if (paymentMethod === 'Klarna') {
            const amount = Math.round(deposit * 100)
            if (amount < 35) {
                setError(`Klarna is not available for this amount. Please try another payment method.`);
                return true
            }
        }
    }


    let deposit = calculateDeposit({
        reservation,
        giftCardAmount,
        isUpdateReservation,
        isUpdateWithVenueChange,
        oldReservation,
    })
    let isSkipPayment = deposit <= 0;
    let isRefundPayment = deposit < 0;
    let isShowRefundPaymentInfo = isUpdateReservation && isSkipPayment && isRefundPayment;
    let isShowSkipPaymentInfo = isUpdateReservation && isSkipPayment && !isRefundPayment;
    let isShowPaymentInfo = isUpdateReservation && !isSkipPayment;
    const isExpressCheckoutDisplaying = venue?.isStripeV2OtherMethodsEnable && !!paymentRequest
    const defaultStripeMethodsCount = Object.values(paymentResult || {}).filter(value => value === true).length;
    const stripeButtonsCount = (venue?.isStripeV2OtherMethodsEnable ? enabledPaymentMethods.length : 0) + defaultStripeMethodsCount;
    const isSavedCardExist = listOfStripeSavedCards && listOfStripeSavedCards.length > 0;

    return (
        <>
            <form id="payment-form" onSubmit={handleSubmit}>

                {
                    isExpressCheckoutDisplaying &&
                    <>
                        {<ExpressCheckout className={`button-grid button-count-${stripeButtonsCount}`}>
                            <PaymentRequestButtonElement options={paymentButtonOptions} />
                            {
                                venue?.isStripeV2OtherMethodsEnable && enabledPaymentMethods.length > 0 && (
                                    <>
                                        {enabledPaymentMethods.map(method => (
                                            <StripeCustomButton key={method} method={method} onClick={() => handleOtherMethodsSubmit(method)} />
                                        ))}
                                    </>
                                )
                            }
                        </ExpressCheckout>}

                        <PaymentDeilimiter />
                    </>
                }

                <PaymentContainer isWithoutHeader={isSavedCardExist}>
                    <StripeManageCards
                        show={isSavedCardExist}
                        cardNumbers={listOfStripeSavedCards.map(method => ({
                            cardEndsWith: method.card.last4,
                            cardType: method.card.display_brand,
                            id: method.id
                        }))}
                        selectedCard={selectedSavedCard}
                        setSelectedCard={(method) => {
                            setSelectedSavedCard(method)
                            setDisabled(method === null)
                        }}
                    >
                        <>
                            {!isSkipPayment && (
                                <>
                                    <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
                                </>
                            )}
                            {(showDiscounts||showGiftCard) && isMobile && (
                                <CouponCodes
                                    reservation={reservation}
                                    applyCouponCode={applyCouponCode}
                                    deposit={deposit}
                                    isSkipPayment={isSkipPayment}
                                    giftBalance={giftBalance}
                                    getGiftCardBalance={getGiftCardBalance}
                                    giftBalanceError={giftBalanceError}
                                    addGiftCard={addGiftCard}
                                    giftCardAmount={giftCardAmount}
                                    />
                            )}

                            {isSaveCardDisplaying ? <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isSaveCard}
                                        onChange={(e) => setIsSaveCard(!isSaveCard)}
                                        name="checked"
                                        color="default"
                                        style={{
                                            color: "#2E3A48",
                                        }}
                                    />
                                }
                                label={<Typography className='body-small'>Save my card for a faster checkout</Typography>}
                                style={checkboxStyle}
                            /> : null}
                            {(isMobile && orderSummary) ? orderSummary(error, (processing || disabled || succeeded || !!error)) : (
                                <>
                                    {(venue?.showPolicy || currentPackage?.showPolicy || currentPackage?.packagePolicyOverride) && (
                                        <VenuePolicies
                                            policyInfo={(currentPackage?.packagePolicyOverride) ? currentPackage?.packagePolicyText : venue?.venueInfo}
                                            policyTitle={(currentPackage?.packagePolicyOverride) ? currentPackage?.packagePolicyTitle : 'VENUE POLICIES'}
                                            notShowCheckbox={currentPackage?.packagePolicyOverride && !currentPackage?.showPolicy}
                                            selected={selected}
                                            onChange={(_, isChecked) => setSelected(!!isChecked)}
                                        />
                                    )}

                                    {(isShowRefundPaymentInfo) && (
                                        <InfoAlert
                                            text="You will receive a refund for the price difference"
                                        />
                                    )}
                                    {(isShowSkipPaymentInfo) && (
                                        <InfoAlert
                                            text="There is no price difference for your new reservation. No additional payment is needed"
                                        />
                                    )}
                                    {isShowPaymentInfo && (
                                        <InfoAlert
                                            text="Your new reservation requires an additional payment"
                                        />
                                    )}
                                </>
                            )}
                            {/* to be used in the future */}
                            {/* <FormControlLabel
                            control={
                                <Box height={42} width={42} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                                    <EncryptedIcon />
                                </Box>
                            }
                            label={<Typography className='body-small'>Secure and encrypted</Typography>}
                            style={checkboxStyle}
                        /> */}
                        </>
                    </StripeManageCards>
                    {(requestError || error) && (
                        <NoAvailiableGuests text={error ? error : requestError} noSpace mb={'24px'} />
                    )}
                    {isMobile ? <>
                        {isSkipPayment && (
                            <MobileAttachedButton
                                reservation={reservation}
                                onButtonClick={
                                    () => createEmptyPayment(PMethod.stripe)
                                }
                                isSummaryExpanded={false}
                                isButtonDisabled={false}
                                venueId={venue?.id}
                                giftCardAmount={giftCardAmount}
                                isUpdateReservation={isUpdateReservation}
                            >
                                {confirmButtonText}
                            </MobileAttachedButton>)
                        }


                        {!isSkipPayment && (
                            <MobileAttachedButton
                                reservation={reservation}
                                isSummaryExpanded={false}
                                isButtonDisabled={(processing || disabled || succeeded || !!error || !selected) && (!selectedSavedCard)}
                                venueId={venue?.id}
                                giftCardAmount={giftCardAmount}
                                isUpdateReservation={isUpdateReservation}
                                button={
                                    <ButtonMain
                                        id="submit"
                                        disabled={(processing || disabled || succeeded || !!error || !selected) && (!selectedSavedCard)}
                                        role="button"
                                        aria-label="submit form"
                                        type="submit"
                                    >
                                        {confirmButtonText}
                                    </ButtonMain>
                                }
                            >
                                {confirmButtonText}
                            </MobileAttachedButton>)
                        }
                    </> : <>


                        {isSkipPayment && (
                            <ButtonMain
                                role="button"
                                onClick={() => createEmptyPayment(PMethod.stripe)}
                                disabled={!selected}
                            >
                                {confirmButtonText}
                            </ButtonMain>
                        )}
                        {!isSkipPayment && (
                            <ButtonMain
                                id="submit"
                                disabled={(processing || disabled || succeeded || !!error || !selected) && (!selectedSavedCard)}
                                role="button"
                                aria-label="submit form"
                                type="submit"
                            >
                                {confirmButtonText}
                            </ButtonMain>
                        )}
                    </>}
                </PaymentContainer>

            </form>
        </>
    );
};

export default StripeForm;
