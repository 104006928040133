import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectProps, makeStyles, useTheme } from '@material-ui/core';
import { BorderAll, Check } from '@material-ui/icons';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import SelectIcon from '../../../../assets/select-icon.svg';
import { useViewport } from '../../../hooks/responsive';

interface Props extends SelectProps {
    optionTitle?: string;
    options?: any[];
    icon?: ReactNode;
    label?: string;
    width?: string;
    onFormClick?: () => void;
    withIcon?: boolean;
    mandatory?: boolean;
    truncateLabel?: boolean;
    errorSelect?: string;
}


export const useSelectWithIconStyles = makeStyles((theme) => ({
    form: {
        backgroundColor: theme.palette.secondary.main,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '16px 24px',
        transition: 'all 0.2s ease',
        borderRadius: '4px',
    },
    formHover: {
        '&:hover': {
            backgroundColor: "#EBEDF4 !important",
        },
    },
    formDisabled: {
        opacity: 0.6,
        cursor: 'auto !important'
    },
    select: {
        color: '#212B36',
        fontWeight: 500,
        fontSize: '16px',
        width: '100%',
        borderBottom: 'none',
        backgroundColor: 'transparent !important',

    },
    inputLabel: {
        marginLeft: '72px',
        marginTop: '5px',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '16px',
        color: '#3F4852',

    },
    withIconInputLabel: {
        '&.MuiInputLabel-shrink': {
            transform: 'translate(0, 17px) scale(0.75)'
        }
    },
    menu: {
        borderRadius: '6px',
        border: '1px solid rgba(0, 0, 0, 0.05)',
        padding: '24px',
        backgroundBlendMode: 'soft-light, normal, luminosity',
        background: 'rgba(218, 218, 218, 0.70)',
        backdropFilter: 'blur(12px)',
        boxShadow: '0px 4px 16px 0px rgba(0, 0, 0, 0.20)',
        marginLeft: '-24px',
        marginTop: '16px',

    },
    menuWithIcon: {
        marginLeft: '-58px',

    },
    menuWithIconMobile: {
        marginLeft: '-4px',
    },
    menuMobile: {
        marginLeft: '-8px',
    },
    menuList: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    menuItem: {
        fontSize: '16px', // Adjusted for consistency with iOS
        fontWeight: 400,
        color: '#2E3A48',
        lineHeight: '20px',
        borderRadius: '6px',
        paddingLeft: '24px',
        transition: 'none',
        '&:hover': {
            backgroundColor: '#3899FE',
            color: 'white',
        },
        '&:focus': {
            backgroundColor: 'transparent',
            color: '2E3A48',
            '&:hover': {
                backgroundColor: '#3899FE',
                color: 'white',
            },
        },
        position: 'relative',
        padding: '4px 32px',
        '&.Mui-selected': {
            backgroundColor: 'transparent',
            color: '#212B36'
        },
        '&.Mui-selected:hover': {
            backgroundColor: '#3899FE',
            color: 'white'
        },
        marginBottom: '1px',
        '&:last-child': {
            marginBottom: 0
        }

    },
    menuWithDuration: {
        width: '348px',
    },
    menuItemAge: {
        fontSize: '16px',
        fontWeight: 400,
        color: '#2E3A48',
        lineHeight: '20px',
        borderRadius: '6px',
        transition: 'none',
        position: 'relative',

        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px 20px 40px',
        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        '&:last-child': {
            marginBottom: 0,
            borderBottom: 'none',
        },
        '&.Mui-selected': {
            backgroundColor: 'transparent !important',
        },
        '&.Mui-selected:hover': {
            backgroundColor: 'transparent !important',
        },
        '&:hover': {
            backgroundColor: 'transparent !important',
        },
    },
    menuItemLastChild: {
        border: 'none'
    },
    focused: {
        marginTop: '16px',
    },
    focusedTablet: {
        marginTop: '8px !important'
    },
    focusedWithIcon: {
        marginTop: '6px',
        marginLeft: '52px'
    },
    focusedTabletWithIcon: {
        marginTop: '-7px !important'

    },
    truncateLabel: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '75%'
    },
    error: {
        color: '#EE4C1A',
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '20px',
        paddingLeft: '24px',
    },
}));


const SelectWithIcon = ({ options, optionTitle, icon, label, width, onFormClick, withIcon, mandatory, truncateLabel, errorSelect, ...props }: Props) => {
    const classes = useSelectWithIconStyles();
    const { isMobile, isTabletScreen } = useViewport();
    const theme = useTheme();

    const selectRef = useRef<HTMLSelectElement | null>(null);
    const [open, setOpen] = React.useState(false);

    const handleFormClick = (event: React.MouseEvent<HTMLDivElement>) => {
        if (onFormClick) {
            onFormClick();
            event.stopPropagation(); // Stop the event propagation to prevent it from reaching parent elements
        } else {
            if (selectRef.current) {
                setOpen(!open)
                console.log(selectRef.current)
            }
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const formControlRef = useRef<HTMLDivElement>(null);
    const [widthOfFormControl, setWidthOfFormControl] = useState(0);

    useEffect(() => {
        if (formControlRef.current) {
            const rect = formControlRef.current.getBoundingClientRect();
            setWidthOfFormControl(rect.width);
        }
    }, [formControlRef.current]);



    return (
        <>
            <FormControl innerRef={formControlRef} className={`${classes.form} ${classes.formHover} v2`} style={{
                padding: icon ? '11px 24px' : '11px 24px',
                width,
                cursor: 'pointer',
                maxHeight: isTabletScreen ? '48px' : '70px',
                border: errorSelect ? '1px solid #EE4C1A' : `1px solid ${theme.palette.secondary.main}`,
            }} onClick={handleFormClick} focused={!!onFormClick}>
                {label && <InputLabel className={`${classes.inputLabel} ${withIcon ? classes.withIconInputLabel : ''} ${truncateLabel ? classes.truncateLabel : ''} ${errorSelect ? 'error-label' : ''}`} classes={{ focused: (withIcon && isTabletScreen) ? classes.focusedTabletWithIcon : isTabletScreen ? classes.focusedTablet : classes.focused, shrink: (withIcon && isTabletScreen) ? classes.focusedTabletWithIcon : isTabletScreen ? classes.focusedTablet : classes.focused, }} style={{ marginLeft: withIcon ? '58px' : icon ? '72px' : '24px', ...((icon) && { marginTop: '16px' }), ...((withIcon) && { marginTop: '1px' }), ...((isTabletScreen) && { marginTop: '-7px' }) }} variant="standard" htmlFor="uncontrolled-native">
                    {label} {mandatory && <span style={{ color: '#EE4C1A' }}>*</span>}
                </InputLabel>}
                {icon && icon}
                <Select
                    native={onFormClick ? true : false}
                    disableUnderline
                    inputRef={selectRef}
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    onBlur={handleClose}
                    onSelect={handleClose}
                    inputProps={{
                        style: {
                            marginLeft: icon ? '24px' : 0,
                            backgroundColor: 'transparent',
                            marginTop: icon ? '12px' : 0,
                            paddingBottom: '2px',
                            fontSize: '16px',
                            fontWeight: 500,
                            color: "#212B36"
                        },
                        onBlur: handleClose,
                        onChange: handleClose,
                        onClick: handleClose
                    }}
                    onClick={(e) => onFormClick ? e.stopPropagation() : null}
                    onMouseDown={(e) => onFormClick ? e.preventDefault() : null}
                    className={classes.select}
                    value={props.value ?? ""}
                    MenuProps={{
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        },
                        transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                        classes: {
                            paper: `${classes.menu} ${(withIcon && !isMobile) && classes.menuWithIcon} ${(withIcon && isMobile) && classes.menuWithIconMobile} ${(isMobile && !withIcon) && classes.menuMobile}`,
                            list: classes.menuList
                        },
                        PaperProps: {
                            style: {
                                minWidth: `${widthOfFormControl}px`
                            }
                        }
                    }}
                    renderValue={(o) => o as string}
                    IconComponent={() => <img src={SelectIcon} alt="SelectIcon" style={(icon && isTabletScreen && !withIcon) ? { marginBottom: '-4px' } : isTabletScreen ? { marginBottom: '16px' } : (!icon || withIcon) ? { marginBottom: '12px' } : {}} />}
                    style={
                        withIcon ? { marginLeft: '10px', marginTop: '14px', marginBottom: '-4px' } : {}
                    }

                    {...props}
                >
                    {onFormClick ?
                        <>
                            {optionTitle && <option value={''} disabled>
                                {optionTitle}
                            </option>}
                            {options?.map((option, key) =>
                                <option key={key} value={option} className={classes.menuItem}>
                                    {option}
                                </option>
                            )}
                        </>
                        :
                        options?.map((option, key) =>
                            <MenuItem key={key} value={option} className={classes.menuItem} onClick={handleClose}
                            >
                                {props.value === option && <Check style={{ position: 'absolute', left: 5, top: '50%', transform: 'translateY(-50%)', fontSize: '16px' }} />}
                                {option}
                            </MenuItem >
                        )
                    }

                </Select >

            </FormControl >
            {errorSelect && <FormHelperText className={classes.error}>{errorSelect} </FormHelperText>}
        </>
    )
}

export default SelectWithIcon