import { initializeIcons } from "@uifabric/icons";
import { ConnectedRouter } from "connected-react-router";
import mixpanel from 'mixpanel-browser';
import React, { useEffect } from "react";
import { ConnectedProps, Provider, connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { LastLocationProvider } from "react-router-last-location";
import { getUIConfigAction } from "./actions/ui-actions";
import Account from "./components/Account/Account";
import AccountCancellation from "./components/AccountCancellation/AccountCancellation";
import AuthManager from "./components/AuthManager";
import ConfirmAleternativePaymentReservation from "./components/ConfirmAleternativePaymentReservation/ConfirmAleternativePaymentReservation";
import CustomTab from "./components/CustomTab/CustomTab";
import Home from "./components/Home/Home";
import MakePackageReservation from "./components/MakePackageReservation/MakePackageReservation";
import MakePartyConfirmation from "./components/MakePartyConfirmation/MakePartyConfirmation";
import MakePartyReservation from "./components/MakePartyReservation/MakePartyReservation";
import Memberships from "./components/Memberships/Memberships";
import RepayReservation from "./components/RepayReservation/RepayReservation";
import ReservationInfo from "./components/ReservationInfo/ReservationInfo";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import DemoLink from "./components/common/DemoLink";
import LabelLink from "./components/common/LabelLink";
import { ViewportProvider, useViewport } from "./hooks/responsive";
import { selectBootstrapped, selectUIConfig } from "./reducers/ui-reducer";
import { selectVenue } from "./reducers/venues";
import createStore from "./store";
import history from "./store/history";
import { State } from "./store/types";
import { ThemeProvider } from "@material-ui/core";
import theme from "./theme";

// @ts-ignore
const mixpanelToken = window.mixpanelToken || "";

const mapDispatchToProps = {
  getUIConfig: getUIConfigAction,
};
const mapStateToProps = (state: State) => ({
  isBootstrapped: selectBootstrapped(state),
  uiConfig: selectUIConfig(state),
  activeVenue: selectVenue(state),
});
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;
const App: React.FunctionComponent<Props> = ({
  getUIConfig,
  isBootstrapped,
  uiConfig,
  activeVenue,
}) => {
  const { isMobile } = useViewport();
  const isAuthenticated = !!localStorage.getItem('auth');
  useEffect(() => {
    getUIConfig();
    if (mixpanelToken) {
      mixpanel.init(mixpanelToken);
    }
  }, []);
  if (!isBootstrapped) {
    return <div>loading...</div>;
  }
  const showDemo = uiConfig?.isDemo && !uiConfig.isHideDemoMessage
  const showSandBoxPayment = !showDemo && (activeVenue?.sandBoxPayment) && !window.location.pathname.includes('select-location')
  return (
    <div className={`root ${isMobile ? 'white' : uiConfig?.bodyBackgroundColor}`}>
      <AuthManager />
      {(showDemo || showSandBoxPayment) && <DemoLink link={uiConfig?.upgradePlanUrl || ''} isSandBoxPayment={showSandBoxPayment} />}
      {!isMobile && <LabelLink />}
      <Switch>
        <Route exact path="/select-location" component={Home} />
        <Route path="/reservation-info" component={ReservationInfo} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/custom-tab" component={CustomTab} />
        <Route path="/account" component={Account} />
        <Route path="/party" component={MakePartyReservation} />
        <Route path="/thank-you" component={MakePartyConfirmation} />
        <Route path="/account-cancellation" component={AccountCancellation} />
        <Route path="/pay-reservation" component={RepayReservation} />
        <Route path="/confirm-alternative-reservation" component={ConfirmAleternativePaymentReservation} />
        <Route path="/package" component={MakePackageReservation} />
        <Route path="/memberships" component={Memberships} authenticated={isAuthenticated} />
        <Redirect to="/select-location" />
      </Switch>
    </div>
  );
};

const AppContainer = () => {
  const { store } = createStore();
  return (
    <Provider store={store}>
      <ViewportProvider>
        <ConnectedRouter history={history}>
          <LastLocationProvider>
            <ThemeProvider theme={theme}>
              <Route path="/" component={connector(App)} />
            </ThemeProvider>
          </LastLocationProvider>
        </ConnectedRouter>
      </ViewportProvider>
    </Provider>
  );
};

initializeIcons();

export default AppContainer;
