import { Box, Typography } from "@material-ui/core";
import { getSearch } from "connected-react-router";
import React from "react";
import { connect, ConnectedProps } from "react-redux";
import confirmationImage from "../../../../../common/assets/confirmation.png";
import { confirmationPageIcons } from "../../../../../common/constants/confirmationPageIcons";
import { addGiftCardAction, getGiftCardBalanceAction, pushUrlPathAction } from "../../../actions/reservation-actions";
import { initialLoadVenuesAction } from "../../../actions/venue-actions";
import { useViewport } from "../../../hooks/responsive";
import useConfirmationScript from "../../../hooks/useConfirmationScript";
import { selectClient, selectGuest } from "../../../reducers/auth-reducer";
import {
  selectGiftCardAmount,
  selectIsUpdateReservation,
  selectOldReservation,
  selectReservation,
  selectReservationAddons,
} from "../../../reducers/reservation";
import { selectUIConfig } from "../../../reducers/ui-reducer";
import { selectCurrentPackage, selectVenue } from "../../../reducers/venues";
import { State } from "../../../store/types";
import ButtonMain from "../../common/ButtonMain/ButtonMain";
import DefaultContainer from "../../common/DefaultContainer/DefaultContainer";
import { OrderSummary } from "../../OrderSummary/OrderSummary";
import './confirmation.scss';
const mapDispatchToProps = {
  pushUrlPath: pushUrlPathAction,
  initialLoadVenues: initialLoadVenuesAction,
  getGiftCardBalance: getGiftCardBalanceAction,
  addGiftCard: addGiftCardAction,
};

const mapStateToProps = (state: State) => ({
  reservation: selectReservation(state),
  client: selectClient(state),
  guest: selectGuest(state),
  venue: selectVenue(state),
  uiConfig: selectUIConfig(state),
  giftCardAmount: selectGiftCardAmount(state),
  currentPackage: selectCurrentPackage(state),
  isUpdateReservation: selectIsUpdateReservation(state),
  oldReservation: selectOldReservation(state),
  reservationAddons: selectReservationAddons(state),
  searchParams: getSearch(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type Props = ConnectedProps<typeof connector>;

const Confirmation = ({
  reservation,
  client,
  guest,
  venue,
  uiConfig,
  pushUrlPath,
  giftCardAmount,
  currentPackage,
  isUpdateReservation,
  reservationAddons,
  searchParams,
  getGiftCardBalance,
  addGiftCard
}: Props) => {
  useConfirmationScript(uiConfig?.confirmationPageScript);

  const backToStartBooking = () => {
    if (uiConfig?.isPackageReservationMode) {
      pushUrlPath("/package");
    } else {
      pushUrlPath("/reservation");
    }
  };

  const { isMobile, isTabletScreen } = useViewport();

  if ((!reservation || !venue || !reservation.number) && !searchParams.includes('session')) {
    console.log("invalid state for confirmation view");
    backToStartBooking();
    return null; // show error
  }

  const email = reservation.email
    ? reservation.email
    : client
      ? client.email
      : guest?.email;

  const uiConfigIcon =
    uiConfig?.confirmationIcon as keyof typeof confirmationPageIcons;
  const isHideDuration =
    uiConfig?.hideDuration || currentPackage?.hidePackageDuration;
  const twelveHourClockFormat = uiConfig?.twelveHourClockFormat || false;
  let confirmationIcon;

  if (uiConfigIcon === "Default") {
    confirmationIcon = confirmationImage;
  } else {
    confirmationIcon = confirmationPageIcons[uiConfigIcon] || confirmationImage;
  }

  return (
    <Box className="confirmation-container confirmation-container-v2" bgcolor={uiConfig?.bodyBackgroundColor}>
      <Box className={`confirmation-inner-container main-content-v2 ${isTabletScreen ? "is-tablet-v2" : ""
        }`}>
        <div
          className={`confirmation-registrationMessage confirmation-registrationMessage-v2  ${isMobile ? "mobile" : "no-mobile"
            } ${isTabletScreen ? "is-tablet-v2" : ""
            }`}
        >
          <DefaultContainer
            className="registrationMessage"
            bgcolor={"transparent"}
            width={"100%"}
            borderRadius={'8px'}
          >
            <Box>
              <img
                className="confirmationImage"
                src={confirmationIcon}
                alt="confirmation image"
              />
              <Typography className="root-text bold confirmation-title-v2">
                Reservation Confirmed
              </Typography>
              <Typography className=" heading-h2 confirmation-number-v2">
                Reservation #{reservation.number}
              </Typography>
              <Typography className="root-text" style={{ maxWidth: "600px" }}>
                A confirmation has been sent to your email address below. Please
                make sure to check your spam or junk folder if you do not
                receive the email within 5 minutes
              </Typography>
              <Typography className="root-text bold confirmation-email-v2">
                {email}
              </Typography>

              <ButtonMain
                style={{ maxWidth: "400px" }}
                name="homePage"
                href={uiConfig?.homeLink}
              >
                GO BACK TO HOMEPAGE
              </ButtonMain>
            </Box>
          </DefaultContainer>
        </div>
        {!isMobile && (
          <>
            <Box className="column-delimiter"></Box>
            <Box className="confirmation-summary">
              <OrderSummary
                reservation={reservation}
                venue={venue}
                completed
                onEdit={backToStartBooking}
                actionText={uiConfig?.actionText}
                isMobile={isMobile}
                isHideDuration={isHideDuration}
                currentPackage={currentPackage}
                giftCardAmount={giftCardAmount}
                getGiftCardBalance={getGiftCardBalance}
                addGiftCard={addGiftCard}
                isUpdateReservation={isUpdateReservation}
                oldDeposit={reservation?.payed}
                reservationAddons={reservationAddons}
                twelveHourClockFormat={twelveHourClockFormat}
                notShowEdit
                notShowContinue
                isSummaryExpanded={false}
              />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default connector(Confirmation);
