import {
  Box,
  Button,
  Chip,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import React, { FormEvent, useState } from "react";
import { ReservationDto } from "../../../../server/src/dto/reservation.dto";
import {
  GiftCardBalanceDto,
  GiftCardBalanceRequestDto,
  GiftCardPaymentRequestDto,
} from "../../../../server/src/dto/giftCard.dto";
import ArrowForwardIcon from "../../../assets/arrow-forward.svgr";
import ConfirmGiftCard from "../../../../common/components/ConfirmGiftCard";
import EnterGiftCardPIN from "../../../../common/components/EnterGiftCardPIN";

interface Props {
  error?: string;
  reservation: ReservationDto;
  applyCouponCode?: (couponCodes: string[]) => void;
  deposit: number;
  isSkipPayment?: boolean;
  giftBalance?: GiftCardBalanceDto;
  getGiftCardBalance?: (cardParams: GiftCardBalanceRequestDto) => void;
  giftBalanceError?: string;
  addGiftCard?: (cardParams: GiftCardPaymentRequestDto) => void;
  giftCardAmount?: number;
}

const CouponCodes = ({
  error,
  reservation,
  applyCouponCode,
  deposit,
  isSkipPayment,
  giftBalance,
  getGiftCardBalance,
  addGiftCard,
  giftCardAmount,
}: Props) => {
  const [couponValue, setCouponValue] = useState("");
  const [errorCoupons, setErrorCoupons] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [isOpenGiftCardModal, setIsOpenGiftCardModal] =
    useState<boolean>(false);
  const [giftCardNumber, setGiftCardNumber] = useState<string>("");
  const [giftCardPin, setGiftCardPin] = useState<string>("");
  const [showPinInput, setShowPinInput] = useState<boolean>(false);

  const disabled = (deposit === 0 || !!isSkipPayment) && !giftCardAmount

  const removeGiftCard = () => {
    console.log("remove gift card");
  };
  
  const isValidGiftCardNumber = (number: string) => {
    // This is a placeholder regex from 16 till 20 digits
    const giftCardRegex = /^[0-9]{16,20}$/;
    return giftCardRegex.test(number);
  };

  const handleAddCoupon = (e: FormEvent) => {
    e.preventDefault();
    setErrorCoupons("");
    if (!couponValue) return;

    if (couponValue == "error-code") {
      setErrorCoupons("Enter a valid discount code");
    } else if (couponValue && isValidGiftCardNumber(couponValue)) {
      //process it as gift card
      setGiftCardNumber(couponValue);
      setShowPinInput(true);
    } else if (couponValue !== "") {
      const coupons = reservation.coupons
        ? reservation.coupons.map((c) => c.code)
        : [];
      const allCoupons = [...coupons, couponValue];

      applyCouponCode && applyCouponCode(allCoupons);
      setCouponValue("");

      setIsClicked(false);
    }
  };
  const handleTextFieldClick = () => {
    setIsClicked(true);
  };

  const handleTextFieldBlur = () => {
    if (couponValue === "") {
      setIsClicked(false);
    }
  };

  const handleDeleteCoupon = (couponItem: string) => {
    const coupons = reservation.coupons
      ? reservation.coupons
          .filter((coupon) => coupon.code !== couponItem)
          .map((c) => c.code)
      : [];
    applyCouponCode && applyCouponCode(coupons);
  };
  const onApplyGiftCardPIN = (pin?: number) => {
    setShowPinInput(false);
    if (!pin) return;
    setGiftCardPin(pin.toString());

    setIsOpenGiftCardModal(true);
    const cardParams = {
      cardNumber: giftCardNumber,
      pin: pin.toString(),
    };
    getGiftCardBalance && getGiftCardBalance(cardParams);
  };
  const onApplyGiftCard = (giftCardTotal?: number) => {
    setIsOpenGiftCardModal(false);
    if (!!giftCardTotal && giftCardTotal > 0) {
      const newGiftCardParams = {
        cardNumber: giftCardNumber,
        pin: giftCardPin,
        amount: giftCardTotal,
      };
      addGiftCard && addGiftCard(newGiftCardParams);
    }

  };

  return (
    <>
      <Box className="order-summary-discount">
        <form
          onSubmit={handleAddCoupon}
          className="order-summary-discount-form"
        >
          <TextField
            label="Promo Code or Gift Card"
            variant="outlined"
            margin="none"
            fullWidth
            error={!!error}
            helperText={error}
            size="small"
            focused={isClicked}
            value={couponValue}
            disabled={disabled}
            onChange={(e) => setCouponValue(e.target.value)}
            style={{
              border: "none",
              transition: "all 0.3s ease",
            }}
            FormHelperTextProps={{
              style: {
                marginLeft: 0,
              },
            }}
            InputProps={{
              style: {
                backgroundColor: isClicked ? "#F6F7FA" : "#1A1A1A0D",
                color: "#707983",
                border: "none",
                width: "100%",
                transition: "width 0.15s ease",
              },
              onClick: handleTextFieldClick,
              onBlur: handleTextFieldBlur,
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{
                    opacity: isClicked ? 1 : 0,
                    transition: "opacity 0.15s ease",
                  }}
                >
                  <Button
                    disabled={couponValue === ""}
                    type="submit"
                    onClick={() => setIsClicked(false)}
                    style={{
                      background: !!error
                        ? "#f44336"
                        : couponValue !== ""
                        ? "#3f51b5"
                        : "#e0e0e0",
                      padding: "3px",
                      minWidth: 0,
                    }}
                  >
                    <ArrowForwardIcon />
                  </Button>
                </InputAdornment>
              ),
            }}
          />

          <Box
            display={"flex"}
            style={{ gap: "12px", flexWrap: "wrap" }}
            marginTop={"12px"}
          >
            {reservation.coupons?.map((coupon) => {
              return (
                <Chip
                  key={coupon.code}
                  size="small"
                  style={{ borderRadius: "4px" }}
                  label={coupon.name}
                  onDelete={() => handleDeleteCoupon(coupon.code)}
                />
              );
            })}
          </Box>
        </form>
      </Box>
      {showPinInput && <EnterGiftCardPIN onClose={onApplyGiftCardPIN} />}
      {isOpenGiftCardModal && (
        <ConfirmGiftCard
          onClose={onApplyGiftCard}
          deposit={deposit}
          giftBalance={giftBalance}
        />
      )}
     
    </>
  );
};

export default CouponCodes;
