import { Box, FormHelperText, makeStyles } from '@material-ui/core';
import React from 'react';
import PhoneInputOrig, { Props as PhoneInputProps, DefaultInputComponentProps } from "react-phone-number-input";
import { useViewport } from '../../../hooks/responsive';
import '../../../../../common/components/PhoneInput/phoneInput.scss';
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.secondary.main,
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '28px',
        color: '#212B36',
        padding: '16px 0px',
        height: '70px',
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: '4px',
        '&:focus': {
            border: '1px solid #0000001A',
            backgroundColor: "#EBEDF4 !important",

        },
        transition: 'all 0.2s ease',
        '&:hover': {
            backgroundColor: "#EBEDF4 !important",
        },
    },
    rootTablet: {
        height: '48px'
    },
    input: {
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '28px',
        color: '#212B36',
        border: 'none'

    },

    form: {
        backgroundColor: theme.palette.secondary.main,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '16px 24px'
    },
    inputLabel: {
        marginLeft: '72px',
        // marginTop: '16px',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '16px',
        color: '#3F4852'
    },
    focused: {
        marginTop: '16px',
        transform: 'translate(0, 12px) scale(0.75)',
        color: '#3F4852',

    },
    error: {
        color: '#EE4C1A',
        paddingLeft: '24px'
    }
}));

interface Props extends Readonly<PhoneInputProps<DefaultInputComponentProps>> {
    label?: string;
    fullWidth?: boolean;
    errorInput?: string;
    isReversed?: boolean;
}

const PhoneInput = ({ label, fullWidth, errorInput, isReversed, ...props }: Props) => {
    const classes = useStyles();
    const { isTabletScreen } = useViewport()

    return (
        <Box display='flex' flexDirection='column' width={'100%'} className='phone2'>

            <PhoneInputOrig limitMaxLength defaultCountry="US" className={`${classes.root} ${errorInput ? 'phone-error-v2' : ''} ${isReversed ? 'reversed' : ""} ${isTabletScreen ? classes.rootTablet : ''}`} style={isReversed ? {
                display: 'flex', flexDirection: 'row-reverse', paddingInline: '24px', ...(errorInput ? { border: '1px solid #EE4C1A' } : errorInput ? { border: '1px solid #EE4C1A' } : {})
            } : {}} {...props} />

            {errorInput && <FormHelperText className={classes.error}>{errorInput} </FormHelperText>
            }
        </Box>)
}

export default PhoneInput