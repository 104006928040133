import { Box, Typography } from "@material-ui/core";
import React, { useState } from 'react';
import { ConnectedProps, connect } from "react-redux";
import HtmlRenderer from '../../../../../common/components/HTMLRenderer/HTMLRenderer';
import { startBookingAction } from "../../../actions/reservation-actions";
import { backToReservationAction } from '../../../actions/venue-actions';
import { useViewport } from '../../../hooks/responsive';
import { selectClient } from "../../../reducers/auth-reducer";
import { selectGiftCardAmount, selectIsRequestInProgress, selectIsUpdateReservation, selectOldReservation, selectReservation, selectReservationAddons } from "../../../reducers/reservation";
import { selectUIConfig } from "../../../reducers/ui-reducer";
import { selectCurrentPackage, selectVenue } from "../../../reducers/venues";
import { Addon, State } from "../../../store/types";
import FilterButtons from '../../MakePackageReservation/FIlterButtons';
import { OrderSummary } from "../../OrderSummary/OrderSummary";
import NoAvailiableGuests from '../../PackageCard/NoAvailiableGuests';
import ButtonMain from '../../common/ButtonMain/ButtonMain';
import MobileAttachedButton from "../../common/MobileAttachedButton/MobileAttachedButton";
import AddonItem from './AddonItem';
import './Addons.scss';
import { Value } from './AddonsFilters';

const mapStateToProps = (state: State) => ({
    uiConfig: selectUIConfig(state),
    currentPackage: selectCurrentPackage(state),
    reservation: selectReservation(state),
    activeVenue: selectVenue(state),
    isUpdateReservation: selectIsUpdateReservation(state),
    oldReservation: selectOldReservation(state),
    giftCardAmount: selectGiftCardAmount(state),
    reservationAddons: selectReservationAddons(state),
    isRequestInProgress: selectIsRequestInProgress(state),
    client: selectClient(state),
});

const mapDispatchToProps = {
    startBooking: startBookingAction,
    backToReservation: backToReservationAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);


const Addons = (
    {
        uiConfig,
        startBooking,
        currentPackage,
        reservation,
        activeVenue,
        isUpdateReservation,
        oldReservation,
        giftCardAmount,
        backToReservation,
        reservationAddons,
        isRequestInProgress,
        client
    }: ConnectedProps<typeof connector>
) => {
    const isHideDuration = uiConfig?.hideDuration || currentPackage?.hidePackageDuration;
    const twelveHourClockFormat = uiConfig?.twelveHourClockFormat || false;
    const { isMobile, isTabletScreen } = useViewport();
    const { date } = reservation;
    const [showRequiredError, setShowRequiredError] = useState(false);
    const [isAddingAddons, setIsAddingAddons] = useState(false);

    const onContinue = () => {
        if (isSomeRequiredAddonsNotSelected()) {
            setShowRequiredError(true);
            return
        }

        setShowRequiredError(false)

        if (currentPackage) {
            if (!activeVenue || !currentPackage) return;
            startBooking({ venue: activeVenue, date, currentPackage, isContinue: true });
        } else {
            if (!activeVenue) return;
            startBooking({ venue: activeVenue, date, isContinue: true });
        }
    }
    const extractUniqueTags = () => {
        const uniqueTagsSet = new Set();

        if (activeVenue) {
            activeVenue.addons.forEach((addon) => {
                const tagsArray = addon.tags.split(';');
                tagsArray.forEach((tag) => {
                    const trimmedTag = tag.trim();
                    if (trimmedTag) {
                        uniqueTagsSet.add(trimmedTag);
                    }
                });
            });
        }

        return Array.from(uniqueTagsSet).map(tag => ({ value: tag })) || [];
    };

    const [selectedValue, setSelectedValue] = useState<string | null>('All');

    // useEffect(() => {
    //   if (uiConfig?.hideAllPackageTab) {
    //     if (extractUniqueTags().length > 0) {
    //       handleFilterChange(extractUniqueTags()[0].value as string);
    //     }
    //   }
    // }, [uiConfig, activeVenue?.addons, activeVenue?.addons.length, activeVenue?.addons[0]?.tags])


    const [addonsFiltered, setAddonsFiltered] = useState<Addon[]>(activeVenue?.addons as Addon[])

    const handleFilterChange = (value: string) => {
        setSelectedValue(value);

        if (value === 'All') setAddonsFiltered(activeVenue?.addons as Addon[])
        else {
            setAddonsFiltered(activeVenue?.addons.filter(addon => addon.tags.includes(value)) as Addon[])
        }
        // Do additional logic if needed
    };

    const isSomeRequiredAddonsNotSelected = () => {
        const requiredAddons = activeVenue?.addons.filter(addon => addon.required && (addon.membershipPlanIds.length === 0 || !addon.membershipPlanIds)) || []
        return requiredAddons.some(addon => !reservationAddons.some(reservationAddon => reservationAddon.addonId === addon.id))
    }
    const isContinueDisabled = isRequestInProgress || isAddingAddons


    return (
        <div className="addons-container">
            <div className={`addons-inner-container main-content-v2  ${isMobile ? `v2-mobile` : ''} ${(isTabletScreen) ? `v2-tablet` : ''}`}>
                <div
                    className={`addons ${(!isMobile) ? "addons-v2" : ""} ${isMobile ? "addons-v2-mobile" : ""} ${(isTabletScreen) ? "addons-v2-tablet" : ""} `}
                >
                    <Typography className="title">
                        <HtmlRenderer html={activeVenue?.addonsTitle || "Add Ons"} />
                    </Typography>
                    <Typography className="sub-title">
                        <HtmlRenderer html={activeVenue?.addonsDescription || "Add additional options to your purchase"} />
                    </Typography>
                    <Box>

                        {extractUniqueTags().length > 0 ? <FilterButtons
                            values={[...(uiConfig?.hideAllPackageTab ? [] : [{ value: 'All' }]), ...extractUniqueTags()] as Value[]}
                            filterValue={selectedValue || ''}
                            onFilterChange={handleFilterChange}
                            style={{
                                margin: isMobile ? '32px 0' : '40px 0',
                                marginBottom: '40px',
                                maxWidth: isTabletScreen ? '100%' : isMobile ? '100vw' : '816px',
                                marginLeft: isMobile ? '-24px' : '-17px',
                                marginRight: isMobile ? '-24px' : 0,
                                overflowX: isMobile ? 'auto' : 'visible',
                            }}
                        /> : <Box height={'40px'} />}
                        <Box display={isTabletScreen ? 'grid' : 'flex'} gridTemplateColumns={'1fr 1fr'} justifyContent={'space-between'} flexDirection={isMobile ? 'column' : 'row'} flexWrap={isMobile ? 'no-wrap' : 'wrap'} style={{ gap: isTabletScreen ? '16px' : '24px' }} data-testid="addons-container">
                            {addonsFiltered?.map(
                                (item: Addon) => (
                                    <Box key={item.id}  >
                                        <AddonItem addon={item} setAddingAddons={setIsAddingAddons} />
                                    </Box>))}
                        </Box>
                        {addonsFiltered?.some(addon => addon.required) ?
                            <Box mt={'22px'} mb={'24px'}>
                                <Typography className='root bold required-tag'><span className='required-star'>*</span>Required Add On</Typography>
                            </Box> : <Box height={'24px'} />}

                    </Box>
                    {(showRequiredError && <Box marginBottom={'24px'} marginTop={addonsFiltered?.some(addon => addon.required) ? '' : '24px'}>
                        <NoAvailiableGuests
                            text='Select all required add ons'
                            noSpace
                        /></Box>)}
                    {isMobile && <Box height={'48px'} />}
                    {isMobile ? (
                        <MobileAttachedButton
                            reservation={reservation}
                            onButtonClick={onContinue}
                            isButtonDisabled={isContinueDisabled}
                            giftCardAmount={giftCardAmount}
                            isUpdateReservation={isUpdateReservation}
                            venueId={activeVenue?.id} />
                    ) : (
                        <ButtonMain onClick={onContinue} disabled={isContinueDisabled} buttonClassName="btn-addons">Continue</ButtonMain>
                    )}
                </div>

                {!isMobile && (
                    <>
                        <div className="column-delimiter"></div>
                        <div className="reservation-summary">

                            <OrderSummary
                                reservation={reservation}
                                venue={activeVenue}
                                onEdit={backToReservation}
                                actionText={uiConfig?.actionText}
                                isMobile={isMobile}
                                isHideDuration={isHideDuration}
                                currentPackage={currentPackage}
                                giftCardAmount={giftCardAmount}
                                isUpdateReservation={isUpdateReservation}
                                oldDeposit={reservation?.payed}
                                reservationAddons={reservationAddons}
                                twelveHourClockFormat={twelveHourClockFormat}
                                notShowContinue
                                isLoading={isRequestInProgress}

                            />

                        </div>
                    </>
                )}
            </div>

        </div >
    );
};

export default connector(Addons);