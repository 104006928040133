import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
    palette: {
        secondary: {
            main: "#EBECF0",
        }
    },
    typography: {
        fontFamily: "Roboto, sans-serif",
    },
});

export default theme;
