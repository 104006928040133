import {
    Box,
    Button,
    FormControl,
    InputLabel,
    Modal,
    ModalProps,
    Select,
    Typography,
    makeStyles,
    useTheme
} from '@material-ui/core';
import { difference, orderBy } from 'lodash';
import React, { ChangeEvent, Fragment, LegacyRef, useState } from 'react';
import { useSelector } from 'react-redux';
import HtmlRenderer from '../../../../../common/components/HTMLRenderer/HTMLRenderer';
import { AddonModifierDto } from '../../../../../server/src/dto/addonModifier.dto';
import { ReservationAddonDto } from '../../../../../server/src/dto/reservationAddon.dto';
import { VenueDto } from '../../../../../server/src/dto/venue.dto';
import { ModifierType } from '../../../../../server/src/entities/enums';
import defaultImage from "../../../../assets/default-image.jpg";
import SelectIcon from '../../../../assets/select-icon.svg';
import TrashIcon from '../../../../assets/trash.svgr';
import { Addon, CurrencyType, State } from '../../../store/types';
import ButtonMain from '../../common/ButtonMain/ButtonMain';
import TextArea2 from '../../common/Input/TextArea2';
import { AddonWithQuantity } from './AddonItem';
import AddonMultiSelect from './AddonMultiSelect';
import AddonSingleSelect from './AddonSingleSelect';
import { getSelectedModifiersAndOptions } from './utils';
interface Props extends Omit<ModalProps, 'open' | 'children'> {
    containerRef: LegacyRef<HTMLDivElement>;
    addon: Addon;
    price: string;
    quantityOptions: {
        key: string;
        text: string;
    }[];
    toggleSelected: (addon: Addon, isSelected: boolean, quantity: number, modifiers?: AddonModifierDto[], notes?: string) => void;
    setAddonsSelected: React.Dispatch<React.SetStateAction<AddonWithQuantity[]>>;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    onRequestForSummary?: () => void;
    addonsSelected: ReservationAddonDto[];
    activeVenue?: VenueDto;
    reservationAddons: ReservationAddonDto[];
}

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backdropFilter: 'blur(5px)',
        backgroundColor: 'rgba(243, 243, 245, 0.80)',
    },
    name: { fontSize: '24px', fontWeight: 600, maxWidth: '210px' },
    price: { fontSize: '24px', fontWeight: 600 },
    description: {
        color: '#3F4852', fontSize: '16px', fontWeight: 400, marginBottom: '16px'
    },
    modifierName: { color: '#3F4852', fontSize: '16px', fontWeight: 600 },
    choose: { color: '#212B36', fontSize: '14px', fontWeight: 400 },

    form: {
        backgroundColor: theme.palette.secondary.main,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '16px 24px',
        transition: 'all 0.2s ease',
        borderRadius: '4px',
        '&:hover': {
            backgroundColor: "#EBEDF4 !important",
        },
    },
    select: {
        color: '#212B36',
        fontWeight: 500,
        fontSize: '16px',
        width: '100%',
        borderBottom: 'none',
        backgroundColor: 'transparent !important',
        height: '56px',


    },
    inputLabel: {
        marginLeft: '72px',
        marginTop: '5px',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '16px',
        color: '#3F4852'
    },
    focused: {
        marginTop: '10px',
    }
}));


const AddonModal: React.FC<Props> = ({ containerRef, reservationAddons, addon, price, quantityOptions, toggleSelected, setAddonsSelected, activeVenue, setOpen, onRequestForSummary, addonsSelected, ...props }) => {
    const uiConfig = useSelector((state: State) => state.uiState.uiConfig)
    const [selectedModifiers, setSelectedModifiers] = useState<AddonModifierDto[]>(addonsSelected.find(addonSelected => (addonSelected.addonId === addon.id) || (addonSelected.id === addon.id))?.modifiers || getSelectedModifiersAndOptions(addon, reservationAddons) || [])
    const currency = uiConfig?.currency || CurrencyType.USD;
    const theme = useTheme()

    const [quantity, setQuantity] = useState<string | null>(
        reservationAddons?.find((add => add.addonId === addon.id))?.quantity.toString() ||
            addon.matchGuestCount ?
            quantityOptions[0].text :
            addonsSelected.find(addonSelected => addonSelected.addonId === addon.id)?.quantity.toString() || '1'
    );
    const [quantityParam, setQuantityParam] = useState<{
        addonId: string;
        quantity: string;
    } | null>(null);
    const [notes, setNotes] = useState<string>(addonsSelected.find(addonSelected => addonSelected.addonId === addon.id)?.notes || reservationAddons.find(addonSelected => addonSelected.addonId === addon.id)?.notes || '');
    const classes = useStyles();

    const handleChangeNotes = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setNotes(event.target.value)
    }



    const handleChange = (event: ChangeEvent<{ name?: string | undefined; value: unknown; }>) => {
        setQuantityParam({
            addonId: addon.id,
            quantity: event.target.value as string,
        })
        setQuantity(event.target.value as string)
    };
    const handleClear = () => {
        // setAddonsSelected()
        toggleSelected(addon, false, 0, selectedModifiers)
        setAddonsSelected(addons => addons.filter(add => add.id !== addon.id))
        setQuantity('1')
        setSelectedModifiers([])

        setOpen(false);
        onRequestForSummary && onRequestForSummary()
    }

    const handleAdd = () => {
        toggleSelected(addon, true, Number(quantityParam?.quantity) || +quantityOptions[0].key, selectedModifiers, notes)
        setAddonsSelected(addons => [...addons, { ...addon, quantity: Number(quantity) }])

        setOpen(false);
        onRequestForSummary && onRequestForSummary()
    }

    const requiredModifierId = addon.modifiers?.filter(modifier => modifier.required).map(modifier => modifier.id);
    const selectedModifierIds = selectedModifiers.map(modifier => modifier.id);
    const notSelectedRequiredModifier = difference(requiredModifierId, selectedModifierIds);

    const isAddingDisabled = !quantity || (notSelectedRequiredModifier.length > 0);
    const modifiers = orderBy(addon?.modifiers || [], ['priority'], ['desc']);

    const getModifierText = (modifier: AddonModifierDto): string | null => {
        if (modifier.modifierType === ModifierType.multiSelect) {
            if (modifier.minimum === modifier.maximum) {
                return `Choose ${modifier.maximum}`;
            } else if (modifier.minimum > 0) {
                return `Choose ${modifier.minimum} to ${modifier.maximum}`;
            } else {
                return `Choose up to ${modifier.maximum}`;
            }
        } else if (modifier.modifierType === ModifierType.singleSelect) {
            return modifier.minimum > 0 ? `Choose 1` : null;
        }
        return null;
    };


    return (
        <Modal {...props} open style={{ zIndex: 99999999 }} className="addon-modal">
            <Box
                className={classes.container}
            >
                <Box display={'flex'} flexDirection={'column'} maxHeight={'99vh'} style={{ overflowY: 'auto' }} borderRadius={'8px'}>
                    <div ref={containerRef}>
                        <img src={addon.image || defaultImage} style={{ width: '400px', height: '280px', objectFit: 'cover' }} alt={addon.name} />
                        <Box p="32px" bgcolor={'white'} width={'400px'} mt={'-4px'}>
                            <Box display={'flex'} justifyContent={'space-between'} mb={'16px'}>
                                <Typography className={classes.name}>{addon.name}</Typography>
                                <Typography className={classes.price}>{price}</Typography>
                            </Box>
                            <HtmlRenderer className={classes.description} html={addon.description} />
                            {
                                modifiers.map(modifier =>
                                    <Fragment key={modifier.id}>
                                        <Box
                                            padding={'16px 24px'}
                                            bgcolor={theme.palette.secondary.main}
                                            display={'flex'}
                                            justifyContent={'space-between'}
                                            alignItems={'center'}
                                            mb={'16px'}
                                        >
                                            <Box>
                                                <Typography className={classes.modifierName}>{modifier.name}</Typography>
                                                {modifier.required && (
                                                    <Typography className={classes.choose}>
                                                        {getModifierText(modifier)}
                                                    </Typography>
                                                )}
                                            </Box>

                                            {modifier.required && <Box
                                                style={{
                                                    fontWeight: 400,
                                                    fontSize: '14px',
                                                    color: '#3F4852',
                                                    padding: '2px 6px',
                                                    lineHeight: '24px'
                                                }}
                                            >
                                                Required*
                                            </Box>}
                                        </Box>

                                        <Box
                                            // overflow={'auto'} maxHeight={'240px'} 
                                            mb={'16px'} width={'350px'} pr={'20px'}>
                                            {modifier.modifierType === ModifierType.singleSelect ?
                                                <AddonSingleSelect selectedModifiers={selectedModifiers} setSelectedModifiers={setSelectedModifiers} currency={currency} modifier={modifier} /> :
                                                <AddonMultiSelect selectedModifiers={selectedModifiers} setSelectedModifiers={setSelectedModifiers} currency={currency} modifier={modifier} />}
                                        </Box>

                                    </Fragment>
                                )
                            }
                            {addon?.allowCustomerNotes && <TextArea2 placeholder='Type your notes here' value={notes as string} onChange={handleChangeNotes} />}

                            <Box display={'flex'} flexDirection={'row'} style={{ gap: '16px', height: '56px' }} className='v2'>
                                {(addonsSelected?.some(add => add.addonId === addon.id)) && <Button className='btn-trash' onClick={handleClear}>
                                    <TrashIcon />
                                </Button>}
                                <FormControl className={classes.form} style={{ padding: '11px 24px', width: '100%', cursor: 'pointer' }}>
                                    <InputLabel className={classes.inputLabel} classes={{ focused: classes.focused, shrink: classes.focused, }} style={{ marginLeft: '24px', }} variant="standard" htmlFor="uncontrolled-native">
                                        Quantity
                                    </InputLabel>
                                    <Select
                                        native
                                        value={quantity}
                                        onChange={handleChange}
                                        placeholder="Quantity"
                                        disableUnderline
                                        inputProps={{
                                            style: {
                                                backgroundColor: 'transparent',
                                            },
                                        }}
                                        className={classes.select}
                                        IconComponent={() => <img src={SelectIcon} alt="SelectIcon" style={{ marginBottom: '12px' }} />}
                                    >
                                        {/* <option value={''} disabled >
                                            Quantity
                                        </option> */}
                                        {quantityOptions.map((quantity) =>
                                            <option key={+quantity.text} value={quantity.text}>{+quantity.text}</option>
                                        )}
                                    </Select>
                                </FormControl>

                                {/* <SelectWithIcon2 label='Quantity' width='100%' options={quantityOptions.map(q => q.text)} value={quantity}
                                    onChange={handleChange} /> */}
                                <ButtonMain
                                    onClick={handleAdd}
                                    disabled={isAddingDisabled}
                                >
                                    Add
                                </ButtonMain>
                            </Box>
                        </Box>
                    </div>
                </Box>
            </Box>
        </Modal>
    );
};

export default AddonModal;
