import { Theme, createStyles, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import ButtonMain from '../ButtonMain/ButtonMain';

interface Props {
    timeCards: React.ReactNode[];
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            position: 'relative',
            width: '100%',
            overflow: 'hidden',
        },
        grid: {
            display: 'grid',
            gap: theme.spacing(2),
            width: '100%',
        },
        viewMoreButton: {
            borderColor: '#212B36',
            marginTop: theme.spacing(2),
            width: '100%',
            textTransform: 'none',
            height: '64px',
            fontSize: '16px'
        },
    })
);

const TimeCardLoader: React.FC<Props> = ({ timeCards }) => {
    const classes = useStyles();
    const [visibleCount, setVisibleCount] = useState<number>(2);

    const handleViewMore = () => {
        setVisibleCount((prevCount) => prevCount + 1);
    };
    return (
        <div className={classes.container}>
            <div className={classes.grid}>
                {timeCards.slice(0, visibleCount).map((timeCard, index) => (
                    <div key={index}>{timeCard}</div>
                ))}
            </div>
            {visibleCount < timeCards.length && (
                <ButtonMain
                    className={classes.viewMoreButton}
                    onClick={handleViewMore}
                    outline
                >
                    Show more times
                </ButtonMain>
            )}
        </div>
    );
};

export default TimeCardLoader;
