import React, { useState } from "react";
import { Modal } from "office-ui-fabric-react";
import { color } from "../../constants/styles";
import FormTextField from "../FormTextField";
import CancelButton from "../CancelButton";
import LinkButton from "../LinkButton";
import ColorButton from "../ColorButton";
import "./enterGiftCardPIN.scss";

const valueFieldStyle = {
  prefix: {
    background: "transparent",
    color: color.grey,
    fontSize: "14px",
    fontFamily: "Inter, sans-serif",
  }
};

type Props = {
  onClose: (pin?: number) => void;
};

const EnterGiftCardPIN = ({ onClose }: Props) => {
  
  const [pin, setPIN] = useState<string>("");
  const [errorGift, setErrorGift] = useState<string>("");

  const onApply = () => {
    if (!pin || pin.length < 4 || pin.length > 8 || !/^\d+$/.test(pin)) {
      setErrorGift("Please enter valid PIN.");
      return
    }
    const numericPin = parseInt(pin, 10);
    if (Number.isNaN(numericPin)) {
      setErrorGift("Invalid PIN format.");
      return;
    }
    onClose(numericPin);
  }

  return (
    <Modal
      isOpen={true}
      onDismiss={() => onClose()}
      isBlocking={true}
      containerClassName="gift-pin"
    >
      <div className="container">
        <div className="header h4">
          <div>Enter Gift Card PIN</div>
          <CancelButton onClick={() => onClose()} />
        </div>
        <FormTextField
          type="number"
          styles={valueFieldStyle}
          value={pin}
          onChange={(_: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) =>
            setPIN(value || "")
          }
          autoComplete="off"
        />
        <div className="error">{errorGift}</div>
        <div className="buttons-container">
          <LinkButton textColor={color.dark2} onClick={() => onClose()}>Cancel</LinkButton>
          <ColorButton textColor={color.dark2} onClick={onApply}>Apply</ColorButton>
        </div>
      </div>
    </Modal>
  );
};

export default EnterGiftCardPIN;
