import {
  Box,
  FormControl,
  FormHelperText,
  makeStyles,
  InputLabel,
  ThemeProvider,
  createTheme,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { useViewport } from "../../../hooks/responsive";
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";

const useStyles = makeStyles((theme) => ({
  input: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "28px",
    color: "#212B36",

    "& .MuiInputBase-input::placeholder": {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "28px",
      color: "#3F4852",
      opacity: 0.7,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      display: "none",
    },
    "& input": {
      padding: "22px 0 7px",
    },
  },

  form: {
    backgroundColor: theme.palette.secondary.main,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "16px 23.5px",
    transition: "all 0.2s ease",
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#EBEDF4 !important",
    },
  },
  inputLabel: {
    marginLeft: "72px",
    // marginTop: '16px',
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "16px",
    color: "#3F4852",

    "& .MuiFormLabel-root.Mui-focused": {
      color: "#3F4852",
    },
  },
  focused: {
    marginTop: "16px",
    transform: "translate(0, 12px) scale(0.75)",
    color: "#3F4852",
  },
  focusedTablet: {
    transform: "translate(0, 12px) scale(0.75)",
    color: "#3F4852",
  },
  error: {
    color: "#EE4C1A",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "20px",
    paddingLeft: "24px",
  },
  truncateLabel: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "90%",
  },
}));

interface Props extends KeyboardDatePickerProps {
  label?: string;
  fullWidth?: boolean;
  errorInput?: string;
  mandatory?: boolean;
  notTotruncateLabel?: boolean;
}

const DatePicker2 = ({
  label,
  fullWidth,
  errorInput,
  mandatory,
  notTotruncateLabel,
  ...props
}: Props) => {
  const classes = useStyles();
  const { isTabletScreen } = useViewport();
  const [isFocused, setIsFocused] = React.useState(false);
  const theme = useTheme()
  return (
    <Box display="flex" flexDirection="column" width={"100%"}>
      <FormControl
        className={classes.form}
        style={{
          padding: "11px 24px",
          border: errorInput
            ? "1px solid #EE4C1A"
            : isFocused
              ? "1px solid #0000001A"
              : `1px solid ${theme.palette.secondary.main}`,
          backgroundColor: errorInput
            ? theme.palette.secondary.main
            : isFocused
              ? "#EBEDF4"
              : theme.palette.secondary.main,
          ...(isTabletScreen ? { maxHeight: "48px" } : {}),
        }}
        fullWidth={fullWidth}
      >
        {label && (
          <InputLabel
            className={`${classes.inputLabel} ${errorInput ? "error-label" : ""
              } ${notTotruncateLabel || isFocused ? "" : classes.truncateLabel} ${isTabletScreen ? classes.focusedTablet : classes.focused
              }`}
            style={{
              marginLeft: "24px",
              marginTop: isTabletScreen ? "-7px" : "6px",
            }}
            variant="standard"
            htmlFor="uncontrolled-native"
          >
            {label} {mandatory && <span style={{ color: "#EE4C1A" }}> *</span>}
          </InputLabel>
        )}

        <MuiPickersUtilsProvider utils={DayjsUtils}>
          <KeyboardDatePicker
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            {...props}
            className={classes.input}
            fullWidth
            inputVariant="outlined"
            variant="inline"
            helperText={null}
            error={false}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
      {errorInput && (
        <FormHelperText className={classes.error}>{errorInput} </FormHelperText>
      )}
    </Box>
  );
};

export default DatePicker2;
