import { Spinner, SpinnerSize } from "office-ui-fabric-react";
import { Checkbox } from "office-ui-fabric-react/lib/Checkbox";
import React, { useEffect, useState } from "react";
import { InfoAlert } from "../../../../../common/components/Alert/Alert";
import { calculateDeposit } from "../../../../../common/utils/formats";
import {
    GiftCardBalance,
    GiftCardBalanceRequest,
    GiftCardPaymentRequest,
    PMethod,
    Payment,
    Reservation,
    Venue,
} from "../../../store/types";
import CouponCodes from "../../OrderSummary/CouponCodes";
import PaymentContainer from "../../ReservationInfo/Payment/PaymentContainer";
import ButtonMain from "../ButtonMain/ButtonMain";
import './fortisForm.scss';
import { PackageNameDto } from "../../../../../server/src/dto/packageName.dto";
import VenuePolicies from "../../ReservationInfo/Payment/VenuePolicies";

interface Props {
    venue: Venue | undefined;
    reservation: Reservation;
    selected: boolean;
    isMobile: boolean;
    showGiftCard: boolean;
    orderSummary: (error?: string, block?: boolean) => JSX.Element;
    uiConfig: any;
    requestError?: string;
    makeFortisReservation: (payment: Payment) => void;
    giftBalance?: GiftCardBalance;
    getGiftCardBalance: (cardParams: GiftCardBalanceRequest) => void;
    giftBalanceError?: string;
    addGiftCard: (cardParams: GiftCardPaymentRequest) => void;
    giftCardAmount: number;
    showDiscounts?: boolean;
    applyCouponCode?: (couponCodes: string[]) => void;
    createEmptyPayment: (paymentType: PMethod) => void;
    isUpdateReservation: boolean;
    isUpdateWithVenueChange: boolean;
    oldReservation?: Reservation;
    fortisClientToken?: string;
    isPaymentInProgress: boolean;
    currentPackage?: PackageNameDto;
}

const FortisForm = ({
    venue,
    reservation,
    selected,
    isMobile,
    orderSummary,
    uiConfig,
    requestError,
    showGiftCard,
    makeFortisReservation,
    giftBalance,
    getGiftCardBalance,
    giftBalanceError,
    addGiftCard,
    giftCardAmount,
    showDiscounts,
    applyCouponCode,
    createEmptyPayment,
    isUpdateReservation,
    isUpdateWithVenueChange,
    oldReservation,
    fortisClientToken,
    isPaymentInProgress,
    currentPackage
}: Props) => {
    useEffect(() => {
        if (!!fortisClientToken) {
            if (deposit <= 0) {
                return;
            }
            const environmentType = venue?.fortisUrl && !venue.fortisUrl.includes("sandbox")
                ? 'production'
                : 'sandbox';
            (window as any).FortisElement.create({
                container: '#payment',
                environment: environmentType,
                theme: 'default',
                showSubmitButton: false,
                hideAgreementCheckbox: true,
                hideTotal: true,
                showReceipt: false,
            });
            (window as any).FortisElement.on('done', (ticketInfo: any) => {
                if (!isPaymentInProgress) {
                    const payment: Payment = {
                        paymentReference: ticketInfo.data?.id,
                        details: ticketInfo.data,
                        postalCode: ticketInfo.data?.billing_zip || '',
                        paymentType: PMethod.fortis,
                    }
                    makeFortisReservation(payment);
                }
            });
            (window as any).FortisElement.on('ready', () => {
                setIsLoadingElement(false);
            });
            (window as any).FortisElement.on('validationError', () => {
                setError('Please enter a valid card parameters');
                setIsLoadingElement(false);
            });
            (window as any).FortisElement.on('error', () => {
                setError('Sorry, we cannot complete payment. Please try again later.');
                setIsLoadingElement(false);
            });
        }
    }, [fortisClientToken]);
    const [isLoadingElement, setIsLoadingElement] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const [isSelected, setIsSelected] = useState<boolean>(selected);

    const handleSubmit = async (ev: any) => {
        ev.preventDefault();
        setError('');
        setIsLoadingElement(true);
        (window as any).FortisElement.submit();
    };
    const confirmButtonText = isUpdateReservation ? "MODIFY RESERVATION" : "COMPLETE YOUR RESERVATION";

    let deposit = calculateDeposit({
        reservation,
        giftCardAmount,
        isUpdateReservation,
        isUpdateWithVenueChange,
        oldReservation,
    })
    let isSkipPayment = deposit <= 0;
    let isRefundPayment = deposit < 0;
    let isShowRefundPaymentInfo = isUpdateReservation && isSkipPayment && isRefundPayment;
    let isShowSkipPaymentInfo = isUpdateReservation && isSkipPayment && !isRefundPayment;
    let isShowPaymentInfo = isUpdateReservation && !isSkipPayment;
    return (
        <PaymentContainer isFortis isLoading={isLoadingElement}>
            <form id="payment-form" className="v2" onSubmit={handleSubmit}>
                {!isSkipPayment && (
                    <>
                        {isLoadingElement && (
                            <div className={`loading ${!isLoadingElement ? "hidden" : ""}`} >
                                <Spinner size={SpinnerSize.large} />
                            </div>
                        )}
                        <div id="payment" className={`fortis-form ${isLoadingElement ? 'hidden' : ""} ${error ? 'form-with-error' : ""}`} />
                    </>
                )}
                {(showDiscounts||showGiftCard) && isMobile && (
                    <CouponCodes
                        reservation={reservation}
                        applyCouponCode={applyCouponCode}
                        deposit={deposit}
                        isSkipPayment={isRefundPayment} />
                )}
                {isMobile ? orderSummary(error, (isPaymentInProgress || isLoadingElement)) : (
                    <>
                        {(venue?.showPolicy || currentPackage?.showPolicy || currentPackage?.packagePolicyOverride) && (
                            <VenuePolicies
                                policyInfo={(currentPackage?.packagePolicyOverride) ? currentPackage?.packagePolicyText : venue?.venueInfo}
                                policyTitle={(currentPackage?.packagePolicyOverride) ? currentPackage?.packagePolicyTitle : 'VENUE POLICIES'}
                                notShowCheckbox={currentPackage?.packagePolicyOverride && !currentPackage?.showPolicy}
                                selected={isSelected}
                                onChange={(_, isChecked) => setIsSelected(!!isChecked)}
                            />
                        )}
                        {(isShowRefundPaymentInfo) && (
                            <InfoAlert
                                text="You will receive a refund for the price difference"
                            />
                        )}
                        {(isShowSkipPaymentInfo) && (
                            <InfoAlert
                                text="There is no price difference for your new reservation. No additional payment is needed"
                            />
                        )}
                        {isShowPaymentInfo && (
                            <InfoAlert
                                text="Your new reservation requires an additional payment"
                            />
                        )}
                        {(requestError || error) && (
                            <div className="card-error" role="alert">
                                {error ? error : requestError}
                            </div>
                        )}
                        {isSkipPayment && (
                            <ButtonMain
                                role="button"
                                onClick={() => createEmptyPayment(PMethod.fortis)}
                                disabled={!isSelected}
                            >
                                {confirmButtonText}
                            </ButtonMain>
                        )}
                        {!isSkipPayment && (
                            <ButtonMain
                                id="submit"
                                disabled={isPaymentInProgress || isLoadingElement || !isSelected}
                                role="button"
                                type="submit"
                                aria-label="submit form"
                            >
                                {confirmButtonText}
                            </ButtonMain>
                        )}
                    </>
                )}
            </form>
        </PaymentContainer>
    );
};

export default FortisForm;
