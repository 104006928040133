import React, { useState, useEffect } from "react";
import { Modal } from "office-ui-fabric-react";
import { GiftCardBalanceDto } from "../../../server/src/dto/giftCard.dto";
import { color } from "../../constants/styles";
import FormTextField from "../FormTextField";
import CancelButton from "../CancelButton";
import LinkButton from "../LinkButton";
import ColorButton from "../ColorButton";
import GrayButton from "../GrayButton";
import "./confirmGiftCard.scss";

const valueFieldStyle = {
  prefix: {
    background: "transparent",
    color: color.grey,
    fontSize: "14px",
    fontFamily: "Inter, sans-serif",
  },
};

type Props = {
  onClose: (gift?: number) => void;
  deposit: number;
  giftBalance?: GiftCardBalanceDto;
};

const ConfirmGiftCard = ({ onClose, deposit, giftBalance }: Props) => {
  const [gift, setGift] = useState<string | undefined>("0.00");
  const [errorGift, setErrorGift] = useState<string>("");

  const totalBalance = `$${giftBalance?.balance?.toFixed(2) || "0.00"}`;

  const PERCENT_STEPS = [25, 50, 75, 100];
  const percents = PERCENT_STEPS.map((percent) => (
    <GrayButton key={percent} onClick={() => onAmountChange(percent / 100)}>
      {percent}%
    </GrayButton>
  ));

  const onApply = () => {
    if (gift) {
      onClose(Number(gift));
      return;
    }
    if (!gift) {
      setErrorGift("This field is required.");
    }
  };
  const onGiftAmountChange = (gift?: string) => {
    setErrorGift("");
    const newGift = gift ? +gift : 0;
    const maxGift =
      giftBalance && +giftBalance?.balance < +deposit
        ? +giftBalance?.balance
        : +deposit;
    if (newGift > maxGift) {
      setGift(maxGift.toFixed(2));
    } else {
      setGift(gift);
    }
  };
  const onAmountChange = (percent: number) => {
    const giftAmount = (giftBalance?.balance || 0) * percent;
    onGiftAmountChange(giftAmount.toFixed(2));
  };
  return (
    <Modal
      isOpen={true}
      onDismiss={() => onClose()}
      isBlocking={false}
      containerClassName="confirm-gift"
    >
      <div className="container">
        <div className="header h4">
          <div>Gift Card</div>
          <CancelButton onClick={() => onClose()} />
        </div>
        <div className="deposit subtitle2">Total Balance: {totalBalance}</div>
        <div className="subtitle2">Please enter the amount to use</div>
        <FormTextField
          type="number"
          prefix="USD"
          styles={valueFieldStyle}
          value={gift}
          onChange={(_: any, value?: string) =>
            onGiftAmountChange(value ? value : "0.00")
          }
          autoComplete="off"
        />
        <div className="percent-buttons">{percents}</div>
        <div className="gift-message">
          Gift cards are non-refundable for reservation cancellations
        </div>
        <div className="error">{errorGift}</div>
        <div className="buttons-container">
          <LinkButton textColor={color.dark2} onClick={() => onClose()}>
            Cancel
          </LinkButton>
          <ColorButton textColor={color.dark2} onClick={onApply}>
            Apply
          </ColorButton>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmGiftCard;
