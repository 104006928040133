import React, { useState, useEffect, useCallback } from "react";
import { Box, makeStyles, Theme, Tooltip, TooltipProps } from "@material-ui/core";
import { useViewport } from "../../hooks/responsive";

interface StyleProps {
    isPressed?: boolean;
}

const useStylesBootstrap = makeStyles<Theme, StyleProps>((theme) => ({
    arrow: {
        color: '#212121',
    },
    tooltip: {
        backgroundColor: (props) => (props.isPressed ? '#EFEFEF' : '#212121'),
        padding: '8px 12px',
        borderRadius: '4px',
        color: (props) => (props.isPressed ? '#212121' : '#EFEFEF'),
        fontSize: '12px',
        fontWeight: 500,
        lineHeight: '18px',
    },
}));

const convertToMultiline = (text: string) => {
    return text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));
};

interface Props extends TooltipProps {
    isPressed?: boolean;
    title: string
}

function BootstrapTooltip(props: Props) {
    const { isPressed, title, ...tooltipProps } = props;
    const classes = useStylesBootstrap({ isPressed });

    const [open, setOpen] = useState(false);
    const { isMobile } = useViewport();

    const handleToggle = useCallback(
        (e: React.MouseEvent) => {
            e.stopPropagation();
            setOpen((prev) => !prev);
        },
        []
    );

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const tooltipEvents = isMobile
        ? { onClick: handleToggle }
        : {
            onMouseEnter: () => setOpen(true),
            onMouseLeave: () => setOpen(false),
        };

    return (
        <Tooltip
            arrow
            interactive
            classes={classes}
            {...tooltipProps}
            open={open}
            onClose={handleClose}
            disableHoverListener={isMobile}
            title={
                <Box textAlign="center">{convertToMultiline(title)}</Box>
            }
        >
            <Box {...tooltipEvents}>{props.children}</Box>
        </Tooltip>
    );
}

export default BootstrapTooltip;
